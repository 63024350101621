import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  position: relative;
  grid-column: 12 / span 7;
  z-index: 2;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 300%;
  }
`

const Inner = styled.div` 
  width: 100%;
  box-sizing: border-box;
  padding: 0;
`

const Content = styled.div` 
  position: relative;
  width: 100%;
  box-sizing: border-box;
  ${media.large`
    padding: 0 60px 0 0;
  `}
`

const BodyText = styled.p`
  ${fontstack.default}
  ${type('m-body')}

  ${media.large`
    ${type('body')}
  `}
  color: #383B23;
  margin: 15px 0 20px;
`

const HeadWrapper = styled.div` 
`

const Title = styled.h4` 
  ${fontstack.default}
  ${type('m-body')}

  ${media.large`
    ${type('body')}
  `}
  
  font-weight: 500;
  color: #383B23;
  margin: 0 0 5px;
`

const Sub = styled.p`
  margin: 0;
  ${fontstack.default}
  ${type('m-body')}

  ${media.large`
    ${type('body')}
    font-weight: 500;
  `}
  color: #383B23;
`

const ForYouRow = (props) => {
  const { children, title, subtitle, reference } = props;
  return (
    <Wrapper>
      <HeadWrapper>
        {title ? <Title>{title}</Title> : null }
        <Sub>{subtitle}</Sub>
      </HeadWrapper>
      <Inner>
        <Content> 
          <BodyText>{ children }</BodyText>
          { reference || null }
        </Content>
      </Inner>
    </Wrapper>
  )
}

export default ForYouRow;