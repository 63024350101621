import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(18, 1fr);
`



const ForYouRow = (props) => {
  const { children, ...rest } = props;
  return (
    <Wrapper {...rest}>
      { children }
    </Wrapper>
  )
}

export default ForYouRow;