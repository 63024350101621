import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar } from "swiper";
import { media } from '../../utils/mediaQuery';
import { StoreContext } from '../../context/store-context';


const Wrapper = styled.div` 
  position: relative;
`

const Process = (props) => {
  const { children } = props;
  const [ Active, setActive ] = useState(null);
  const { width } = React.useContext(StoreContext);

  const onSelect = (index) => {
    setActive(index);
  }

  const onClose = () => {
    setActive(null);
  } 

  useEffect(()=>{
    if(width < 1080) {
      onSelect(0);
    } else {
      onSelect(null);
    }
  },[width])

  const cards = React.Children.map(children, (child, index) => {
    if(React.isValidElement(child)) {
      return (
        <SwiperSlide style={width < 1080 ? {width: '80%'} : null}>
          {React.cloneElement(child,{ id: index, onSelect: onSelect, onClose: onClose, isLast: index === children.length - 1 ? true : false,  active: Active === index ? 1 : 0 })}
        </SwiperSlide>
      );
    }
    return child;
  });

  return (
    <Wrapper> 
      <Swiper
        slidesPerView={width >= 1080 ? 4 : "auto"}
        spaceBetween={width >= 1080 ? 30 : 10}
        className="mySwiper"
        onSlideChange={(e) => {
          onSelect(e.snapIndex);
        }}
        style={{overflow:'visible', padding: width >= 1080 ? '0' : '0 20px 0'}}>
        {cards}
      </Swiper> 
    </Wrapper>
  )
}

export default Process;