import React from 'react';
import styled from 'styled-components';
import ImageBlock from '../ImageBlock/ImageBlock';

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 140px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  border: ${props => props.active ? '1px dashed #383B23' : '1px dashed transparent'};
  transition: all .2s ease;
`

const Inner = styled.div` 
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

const TextureCircle = (props) => {
  const { src, placeholder, active, alt, ...rest } = props;
  return (
    <Wrapper active={active} {...rest}>
      <Inner>
        <ImageBlock src={src} placeholder={placeholder} alt={alt}/>
      </Inner>
    </Wrapper>
  )
}

export default TextureCircle;


const Container = styled.div`
   grid-column: 8 / span 4 ;
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
`

const ContainerInner = styled.div`
  width: 150px;
`

const LineContain = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  display: flex;
`

const VerticalWrapper = styled.div` 
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: ${props => props.bottom ? 'flex-end' : 'flex-start'};

  &:before {
    content: '';
    width: 100%;
    height: ${props => props.full ? '100%' : props.half ? '50%' : '0px'};
    background: #383B23;
  }
`

const HorizontalWrapper = styled.div` 
  flex: 1;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: ${props => props.active ? '#383B23' : 'none'};
  }
`

const TextureCircleContainer = (props) => {
  const { children, active, half, full, bottom } = props;
  return (
    <Container>
      <LineContain></LineContain>
      <ContainerInner>
      { children }
      </ContainerInner>
      <LineContain>
        <HorizontalWrapper active={active}></HorizontalWrapper>
        <VerticalWrapper half={half} full={full} bottom={bottom}></VerticalWrapper>
      </LineContain>
    </Container>
  )
}

export { TextureCircleContainer };