import React, { useState } from 'react';
import styled from 'styled-components';
import HtmlParser from '../HtmlParser/HtmlParser';
import ForYouRow from './ForYouRow';
import ForYouText from './ForYouText';
import ReferenceToggle from '../ReferenceToggle/ReferenceToggle';
import Tag from '../Tag/Tag';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import TextureCircle, { TextureCircleContainer } from './TextureCircle';
import KaleImage from './assets/Kale.jpg';
import KaleBlurImage from './assets/Kale_blur.jpg';
import NutritionIcon from './assets/nutritiion-icon.svg';

const Wrapper = styled.div` 
  position: relative;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  height: 750px;
  width: 100%;
  box-sizing: border-box;

  ${media.large`
    max-width: 1440px;
    margin: 0 auto;
  `}
`

const MainTitle = styled.h2` 
  ${fontstack.default}
  ${type('large')}
  font-weight: 300;
  color: #383B23;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
`

const LabelsWrapper = styled.div` 
  display: flex;
  justify-content: center;
  flex-direction:column;
`

const TexturesWrapper = styled.div` 
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentWrapper = styled.div` 
  position: relative;
  display: flex;
  align-items: center;
  border-left: 1px solid #383B23;
  padding: 0 0 0 30px;
  box-sizing: border-box;

  &:before {
    content: '';
    width: 80px;
    height: 1px;
    background: #767966;
    top: 50%;
    left: -80px;
    position: absolute;
  }

`

const TextureTitle = styled(Tag)`
  position: absolute;
  transform: translate(0, calc(-100% - 40px));
`

const TexturesWrapperInner = styled.div` 
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TexturesNav = styled.div` 
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #383B23;
  padding: 25px 0 0;
  top: calc(100% + 80px);
  min-width: 135px;

  &:before {
    content: '';
    width: 1px;
    height: 30px;
    background: #383B23;
    top: -30px;
    left: 50%;
    position: absolute;
  }
`

const TextureSmallCircle = styled(TextureCircle)` 
  width: 50px;
  height: 50px;
  padding: 0;
  opacity: ${props => props.active ? '1' : '0.3'};
  transition: opacity .2s ease;
  border: none;

  &:hover {
    opacity: 1;
  }

  &:not(:last-of-type) {
    margin: 0 25px 0 0;
  }
`

const ForYouV3 = ({content}) => {
  const [ Active, setActive ] = useState(0);
  const [ ActiveIngredient, setActiveIngredient ] = useState(0);

  const circleProps = [];
  circleProps.push({ active: Active === 0 ? true : false, half: Active !== 0 ? true : false, bottom: true });
  circleProps.push({active: Active === 1 ? true : false, half: Active === 1 ? true : false, full: Active === 2 ? true : false });
  circleProps.push({ active: Active === 2 ? true : false, half: Active === 2 ? true : false });

  const activeContent = content[Active];
  const ingredients = activeContent.ingredients;
  const activeIngredient = ingredients[ActiveIngredient];
  return (
    <Wrapper>
      <MainTitle>What can I do for you?</MainTitle>
      <LabelsWrapper>
      { content ? content.map((row,index) => {
        return (
          <Row onClick={()=>{setActive(index); setActiveIngredient(0); }}>
            <Label bottom={(index !== content.length - 1) ? true : false} active={Active === index ? true : false} icon={row.icon || null}><HtmlParser>{row.label}</HtmlParser></Label>
          </Row>
        )
      }) : null }
      </LabelsWrapper>
      <TexturesWrapper>
        <TexturesWrapperInner>
          <TextureTitle theme='outline'>{ingredients[ActiveIngredient].body.title}</TextureTitle>
          <TextureCircle key={ingredients[ActiveIngredient].body.title} src={ingredients[ActiveIngredient].image} placeholder={ingredients[ActiveIngredient].placeholder} alt={ingredients[ActiveIngredient].alt} active/>
          <TexturesNav>
          { ingredients ? ingredients.map((ingredient,index) => {
          return <TextureSmallCircle onClick={()=>setActiveIngredient(index)} key={ingredient.body.title} src={ingredient.image} placeholder={ingredient.placeholder} alt={ingredient.alt} active={index === ActiveIngredient ? true : false}/>
        }) : null }
          </TexturesNav>
        </TexturesWrapperInner>
      </TexturesWrapper>
      <ContentWrapper>
        <ForYouText subtitle={activeIngredient.body.subtitle} reference={<ReferenceToggle label='See References'><HtmlParser strip={['p']}>{activeIngredient.body.reference}</HtmlParser></ReferenceToggle>}>
          <HtmlParser>{activeIngredient.body.content}</HtmlParser>
        </ForYouText> 
      </ContentWrapper>
    </Wrapper>
  )
}

export default ForYouV3;


const RowWrapper = styled.div`
  position: relative;
`

const Row = (props) => {
  const { children, ...rest } = props;
  return (
    <RowWrapper {...rest}>
      { children }
    </RowWrapper>
  )
}

const LabelWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 15px 0;
  grid-column: 1 / span 7;
  display: flex;
  opacity:  ${props => props.active ? '1' : '0.3'};
  cursor: pointer;
  transition: opacity .2s ease;
`

const TextLabel = styled.h3` 
  ${fontstack.default}
  ${type('body')}
  margin: 0;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: ${props => props.hasIcon ? 'none' : 'block'};
    width: 15px;
    height: 15px;
    background: ${props => props.active ? '#383B23' : 'transparent'};
    border-radius: 50%;
    margin: 0 15px 0 0;
    transition: background .2s ease;
  }
`

const IconWrap = styled.img`
  display: inline-block;
  margin: 0 15px 0 0;
  opacity: ${props => props.active ? '1' : '0'};
  transition: opacity .2s ease;
  width: 20px;
`

const Label = (props) => {
  const { children, active, icon, ...rest } = props;
  return (
    <LabelWrapper active={active} {...rest}>
      <TextLabel active={active} hasIcon={icon||null}>{icon ? <IconWrap active={active} src={icon}/> : null }{ children }</TextLabel>
    </LabelWrapper>
  )
}