import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 40px 0;
  grid-column: 1 / span 7;
  display: flex;
  justify-content: center;
  border-right: ${props => props.active ? 'none' : '1px solid #383B23'} ;
  border-bottom: ${props => props.bottom ? '1px solid #383B23' : 'none'};
  cursor: pointer;
`

const TextLabel = styled.h3` 
  ${fontstack.default}
  ${type('large')}
  font-weight: 300;
  margin: 0;
  width: 75%;
`

const LabelPanel = (props) => {
  const { children, active, ...rest } = props;
  return (
    <Wrapper active={active} {...rest}>
      <TextLabel>{ children }</TextLabel>
    </Wrapper>
  )
}

export default LabelPanel;