import React, { useState } from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import Chevron from './assets/chevron.svg';

const ButtonWrapper = styled.div` 
  display: flex;
  align-items: center;
  margin: 0 0 10px;
`

const ChevronIcon = styled(Chevron)`
  transform: rotate(${props => props.open ? '0deg' : '180deg'});
`

const ContentWrapper = styled.div` 
  color: #383B23;
  ${fontstack.default}
  ${type('caption')}
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  max-height: ${props => props.open ? '500px' : '0'};

  ${media.large`
    padding: 0 60px 0 0;
    position: absolute;
  `}
`

const MainButton = styled.button`
  ${fontstack.default}
  ${type('caption')}

  ${media.large`
    font-size: 13px;
  `}
  color: #383B23;
  border: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0 10px 0 0;
`

const ReferenceToggle = (props) => {
  const { label, children } = props;
  const [ Open, setOpen ] = useState(false);

  return (
    <>
      <ButtonWrapper onClick={()=>setOpen(Open ? false : true)}>
        <MainButton>{ label }</MainButton>
        <ChevronIcon open={Open}/>
      </ButtonWrapper>
      <ContentWrapper open={Open}>
        { children }
      </ContentWrapper>
    </>    
  )
}

export default ReferenceToggle;