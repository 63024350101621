import React from 'react';
import Section from '../Section/Section';
import ModuleDivider from '../ModuleDivider/ModuleDivider';
import ProcessCard from '../Process/ProcessCard';
import Process from '../Process/Process';
import styled from 'styled-components';
import thumbImage from '../Process/assets/Icebar-square-texture.png';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled(Section)`
  overflow: visible;
`

const ProcessWrapper = styled.div`
  margin: 100px -20px 0 -20px;

  ${media.large`
    margin: 100px 0 0 0;
  `}
`

const NatureToYou = ({data}) => {
  return (
    <Wrapper>
      <ModuleDivider>{data.title_header}</ModuleDivider>
      <ProcessWrapper>
        <Process>
          {data.card && data.card.length > 0 ?
            data.card.map((card) => {

              return <ProcessCard card={card}/>
            }) : null 
          }
        </Process>
      </ProcessWrapper>
    </Wrapper>
  )
}

export default NatureToYou;