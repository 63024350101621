import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import Bracket from '../Bracket/Bracket';
import Section from '../Section/Section';
import ImageBlock from '../ImageBlock/ImageBlock';
import ProductDetailMain from './ProductDetailMain';
import SwiperArrow from "../SwiperArrow/SwiperArrow";
import ProductDetailAccordion from './ProductDetailAccordion';
import { media } from '../../utils/mediaQuery';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';


gsap.registerPlugin(ScrollTrigger);


const ModifiedBracket = styled(Bracket)`
    -webkit-mask-image: none;
`

const Viewport = styled.div`
  position: relative;
  height: calc(100vh - 80px);
  min-height: 650px;
`

const HeadViewport = styled(Viewport)` 
  overflow: hidden;
  display: flex;
`

const GalleryViewport = styled(Viewport)` 
  display: flex;
  height: auto;
  padding: 145px 0 0;
`

const Grid = styled(Section)`
  display: grid;
  grid-template-columns: repeat(12,1fr);
  grid-column-gap: 30px;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 150px;
  width: 100%;
`

const GalleryGrid = styled(Grid)`
  margin-top: 0;
  margin-bottom: 0;
  overflow: visible;
`

const OverflowHidden = styled.div` 
  position: relative;
  background: #FAF7F2;
`

const ImageWrapper = styled.div`
  grid-column: 5 / span 4;
  display: flex;
  align-items: center;
` 
const Images = styled.div`
  position: relative;
  width: 100%;
  display: grid;
`

const ImageRounded = styled.div`
  grid-area: 1 / 1;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
`
const MainWrapper = styled.div`
  grid-column: 1 / span 12;  
  box-sizing: border-box;

  ${media.large`
    grid-column: 9 / span 4;
    padding: 4vw 0 0 80px;
  `}
`

const CustomSwiper = styled(Swiper)`
  width: 100%;
`

const AccordionWrapper = styled(MainWrapper)`
  ${media.large`
    padding: 4vw 0 0 80px;
    display: flex;
  `}
`

const AccordionInner = styled.div`
  position: relative;
  width: 100%;
`

const CustomAccordion = styled(ProductDetailAccordion)`
  position: absolute;
  width: 100%;
`

const ProductDetailHead = ({product,content}) => {
  const [_, setInit] = useState(false);
  const ViewportRef = useRef();
  const ImageRef = useRef();
  const SecondImageRef = useRef();
  const Controller = useRef();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const Viewport2Ref = useRef();
  const Image2Ref = useRef();
  const ImagesRef = useRef([]);
  const Controller2 = useRef();
  const {
    media
  } = product;

  const { 
    accordion 
  } = content || {};

  useEffect(()=>{

    /*const theTimeout = setTimeout(()=>{
      Controller.current = ScrollTrigger.create({
        trigger: ViewportRef.current,
        start: "-80px top",
        end: "bottom top",
        markers: false,
        pin: ImageRef.current,
        onUpdate: self => {
          gsap.set(SecondImageRef.current, {opacity: self.progress.toFixed(2)});
        }
      });
  
      Controller2.current = ScrollTrigger.create({
        trigger: Viewport2Ref.current,
        start: "-80px top",
        end: `+=${window.innerHeight*(ImagesRef.current.length+2)}`,
        markers: false,
        pin: true,
        onEnter: self => {
          gsap.set(Image2Ref.current, {opacity: '1'});
        },
        onLeaveBack: self => {
          gsap.set(Image2Ref.current, {opacity: '0'});
        },
        onUpdate: self => {
          const progressDuration = 1 / ImagesRef.current.length;
  
          ImagesRef.current.forEach((image,index) => {
            let opacity = (self.progress - progressDuration*index) / progressDuration;
  
            if(opacity < 0 ) {
              opacity = 0;
            } else if(opacity > 1) {
              opacity = 1;
            }
            gsap.set(image, {opacity: opacity.toFixed(2)});
          })
          //gsap.set(SecondImageRef.current, {opacity: self.progress.toFixed(2)});
        }
      });
    }, 200);*/
    

    return () => {
      /*clearTimeout(theTimeout);
      if(Controller.current && Controller2.current) {
        Controller.current.kill();
        Controller2.current.kill()
      }*/
      
    }

  },[]);

  const firstImageSet = media.filter((image,index) => index < 2);
  const secondImageSet = media.filter((image,index) => index >=1 );
  return (
  <>
    <ModifiedBracket bottom bottomShift zIndex={2} background='#F2EADE'>
      <HeadViewport ref={ViewportRef}>
        <Grid small={true}>
          <ImageWrapper>
            <Images ref={ImageRef}>
              { firstImageSet ? firstImageSet.map((object,index)=> {
                const image = object.image;
                  return ( <ImageRounded ref={index!==0 ? SecondImageRef : null } style={{zIndex:index+1, opacity: index!==0 ? 0 : 1}}>
                    <ImageBlock alt={object.alt} src={image.localFile.childImageSharp.gatsbyImageData.images.fallback.src} placeholder={{ src: image.localFile.childImageSharp.gatsbyImageData.placeholder.fallback, width: image.localFile.childImageSharp.gatsbyImageData.width, height: image.localFile.childImageSharp.gatsbyImageData.height }}/>
                  </ImageRounded> )
              }) : null }
            </Images>
          </ImageWrapper>
          <MainWrapper>
            <ProductDetailMain product={product}/>
          </MainWrapper>
        </Grid>
      </HeadViewport>
    </ModifiedBracket>
    { secondImageSet && secondImageSet.length > 0  && <OverflowHidden>
      <GalleryViewport ref={Viewport2Ref}>
        <GalleryGrid>
          <ImageWrapper>
            <CustomSwiper
              onInit={() => {
                setInit(true);

                }
              }
              effect={'fade'}
              slidesPerView={1}
              loop={true}
              navigation={{
                prevEl: secondImageSet.length > 1 && navigationPrevRef.current,
                nextEl: secondImageSet.length > 1 && navigationNextRef.current,
              }}
              modules={[Autoplay, Navigation, EffectFade]}
            >
              { secondImageSet && secondImageSet.map((object,i) => {
                const image = object.image;
                return (
                  <SwiperSlide key={i}>
                   <ImageRounded>
                    <ImageBlock alt={object.alt} src={image.localFile.childImageSharp.gatsbyImageData.images.fallback.src} placeholder={{ src: image.localFile.childImageSharp.gatsbyImageData.placeholder.fallback, width: image.localFile.childImageSharp.gatsbyImageData.width, height: image.localFile.childImageSharp.gatsbyImageData.height }}/>
                  </ImageRounded>
                </SwiperSlide>
                )
              })}
              {
                secondImageSet.length > 1 && <>
                  <SwiperArrow ref={navigationPrevRef} reverse/>
                  <SwiperArrow ref={navigationNextRef}/>
                </>
              }
            </CustomSwiper>
            {/*<Images style={{opacity:'0'}} ref={Image2Ref}>
            { secondImageSet ? secondImageSet.map((object,index)=> {
              const image = object.image;
                return ( <ImageRounded ref={(ref) => { if(index!==0) { ImagesRef.current[index-1] = ref; }}}  style={{zIndex:index+1,opacity: index !== 0 ? 0 : 1}}>
                  <ImageBlock alt={object.alt} src={image.localFile.childImageSharp.gatsbyImageData.images.fallback.src} placeholder={{ src: image.localFile.childImageSharp.gatsbyImageData.placeholder.fallback, width: image.localFile.childImageSharp.gatsbyImageData.width, height: image.localFile.childImageSharp.gatsbyImageData.height }}/>
                </ImageRounded> )
              }) : null }
            </Images>*/}
          </ImageWrapper>
          <AccordionWrapper>
            <AccordionInner>
            {accordion ? <CustomAccordion content={accordion}/> : null }
            </AccordionInner>
          </AccordionWrapper>
        </GalleryGrid>
      </GalleryViewport>
    </OverflowHidden> }
  </>
  )
}

export default ProductDetailHead;


const ImageLayerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 4;
`

const ImageLayerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;;
`

const ImageLayerInner = styled.div`
  grid-column: 5 / span 4;
`

const SectionWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0 30px;
`

const ImageViewport = styled(Viewport)`
  display: flex;
  align-items: center;
  z-index: 4;
`

const StickyViewport = styled(ImageViewport)` 
  position: sticky;
  top: 0;
  z-index: 3;
`


const ImageLayer = ({images}) => {
  const firstImage = images && images.length > 0 ? images[0] : null;
  const lastImage = images && images.length > 0 ? images[images.length-1] : null;
  return (
    <>
    { firstImage ? 
      <ImageLayerWrapper>
      <SectionWrapper>
        <ImageLayerGrid>
          <ImageLayerInner>
            <StickyViewport>
              <ImageRounded>
                <ImageBlock alt={firstImage.altText} src={firstImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} placeholder={{ src: firstImage.localFile.childImageSharp.gatsbyImageData.placeholder.fallback, width: firstImage.localFile.childImageSharp.gatsbyImageData.width, height: firstImage.localFile.childImageSharp.gatsbyImageData.height }}/>
              </ImageRounded>
            </StickyViewport>
            <ImageViewport>
              <ImageRounded>
                <ImageBlock alt={lastImage.altText} src={lastImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} placeholder={{ src: lastImage.localFile.childImageSharp.gatsbyImageData.placeholder.fallback, width: lastImage.localFile.childImageSharp.gatsbyImageData.width, height: lastImage.localFile.childImageSharp.gatsbyImageData.height }}/>
              </ImageRounded>
            </ImageViewport>
          </ImageLayerInner>
        </ImageLayerGrid>
      </SectionWrapper>
    </ImageLayerWrapper> : ''}
    </>
  )
}