import React from 'react';
import styled from 'styled-components';
import Section from '../Section/Section';
import ModuleDivider from '../ModuleDivider/ModuleDivider';
import BundleProduct from '../BundleProduct/BundleProduct';
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper"; 
import { media } from '../../utils/mediaQuery';
import { StoreContext } from '../../context/store-context';

import "swiper/css";
import "swiper/css/scrollbar";
import "../../styles/swiper-scrollbar.css";

const Wrapper = styled.div`
  overflow: hidden;
  background: #FAF7F2;
`

const BundlesGrid = styled.div`
  position: relative;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  grid-column-gap: 30px;

  ${media.large`
    margin: 40px 0 0;
  `}

`

const BundlesInner = styled.div`
  grid-column: 1 / span 12;
  margin: 50px -20px 0;

  ${media.large`
    grid-column: 4 / span 9;
    margin: 0;
  `}
`

const DesktopGrid = styled.div`
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-column-gap: 30px;
`

const Bundles = ({title, bundles}) => {
  const { width } = React.useContext(StoreContext);
  return (
    <Wrapper>
      <Section>
        <ModuleDivider>Bundles with {title}</ModuleDivider>
        <BundlesGrid>
          <BundlesInner>
            { bundles.length > 3 || width < 1080 ? <Swiper
              slidesPerView={width >= 1080 ? "3" : "auto"}
              spaceBetween={width >= 1080 ? 30 : 10}
              className="mySwiper"
              scrollbar={true}
              modules={[Scrollbar]}
              style={{padding: width >= 1080 ? '0 0 30px' : '0 20px 30px', overflow:'visible'}}>
                { bundles ? bundles.map((edge)=> {
                  const product = edge.node;
                  
                  return <SwiperSlide style={width < 1080 ? {width: '80%'} : null}>
                    <BundleProduct product={product} />
                  </SwiperSlide>
                }) : null }
            </Swiper> : <DesktopGrid>
            { bundles ? bundles.map((edge)=> {
                  const product = edge.node;
                  return <BundleProduct product={product} />
                }) : null }
              </DesktopGrid>}
          </BundlesInner>
        </BundlesGrid>
      </Section>
    </Wrapper>
  )
}

export default Bundles;