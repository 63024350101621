import React, { useState, useRef } from 'react';
import isEqual from "lodash.isequal"
import { AddMini } from '../AddToCart/AddToCart';
import { ArrowLinkSmall } from '../ArrowLink/ArrowLink';
import { LazyBackgroundBlur } from "@manualengineering/react-lazyblur"; 
import { StoreContext } from "../../context/store-context"
import { VariantSelect, VariantSelectItem } from '../VariantSelect/VariantSelect';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import { formatPrice } from "../../utils/format-price";
import { getMetafields } from '../../utils/getMetafields';

const Wrapper = styled.div`
  position: relative;
`

const TopWrapper = styled.div`
  display: flex;
  padding: 0 0 10px;
  justify-content: space-between;
`

const BottomWrapper = styled.div`
  box-sizing: border-box;
  //border: 1px solid #383B23;
  position: relative;
  overflow: hidden;
  padding-bottom: 123%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

const BottomInner = styled.div` 
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
`

const Main = styled.div`
  position: relative;
  background: #383B23;
  flex: 1;
  z-index: 1;
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  ${media.large`
    padding: 30px;
  `}
`

const QuickAdd = styled.div` 
  position: relative;
  padding: 20px 20px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #383B23;
  margin: -10px 0 0;
  z-index: 0;

  ${media.large`
    padding: 24px 30px 14px;
  `}
`

const AddMobile = styled(ArrowLinkSmall)` 
  font-weight: 500;
`

const Price = styled.div` 
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500; 
  color: #383B23;

  ${media.large`
    ${type('body')}
    font-weight: 500; 
  `}
`

const Title = styled.h2` 
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500; 
  color: #383B23;
  text-transform: uppercase;
  margin: 0;
  display: flex;
  align-items: center;
  word-break: keep-all;
  ${media.large`
    ${type('body')}
    font-weight: 500; 
  `}

  &:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid #383B23;
    border-radius: 50%;
    margin: 0 10px 0 0;
    background: ${props => props.opacity ? '#383B23' : 'transparent'};
    transition: background .2s ease;
  }
`

const InfoWrapper = styled.div` 
  border-bottom: 1px solid #F3EFD7;
  padding: 0 0 10px;
`

const HoverWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: ${props => props.opacity};
  transition: opacity .2s ease;
  padding: 15px 20px;
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  will-change: opacity;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
 
  ${media.large`
    padding: 30px;
  `}
`

const LazyBackgroundWrapper = styled.div` 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #383B23;
`

const SelectWrapper = styled.div` 
  position: relative;
  z-index: 1;
  border-top: 1px solid #F3EFD7;
  width: 100%;
`

const SelectTitle = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500; 
  color: #F3EFD7; 
  margin: 10px 0 15px;
  text-transform: uppercase;

  ${media.large`
    ${type('body')}
    font-weight: 500; 
  `}
`

const InfoPrimary = styled.h3`
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500; 
  color: #F3EFD7; 
  margin: 0;
  letter-spacing: 0.050em;
  text-transform: uppercase;

  ${media.large`
    ${type('body')}
    font-weight: 500; 
  `}
`

const InfoSecondary = styled.h3`
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 400;
  color: #F3EFD7; 
  margin: 0;
  letter-spacing: 0.050em;
  ${media.large`
    ${type('body')}
    font-weight: 400; 
  `}
`

const Statement = styled.div`
  ${fontstack.default}
  ${type('m-large')} 
  font-weight: 300;
  color: #F3EFD7; 
  margin: 30px 0 0;
  letter-spacing: 0.030em;

  ${media.large`
    ${type('large')}
    font-weight: 300; 
  `}
`

const BundleProduct = ({product}) => {
  const [ Opacity, setOpacity ] = useState(0);
  const [ Active, setActive ] = useState(false);
  const isClicked = useRef(false);
  const { client, addVariantToCart, loading, didJustAddToCart, width } = React.useContext(StoreContext)
  const { 
    description, 
    featuredImage,
    title,
    handle,
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2
  } = product;
  
  const hasVariants = variants.length > 0
  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1);

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  
  const metafields = getMetafields(product);

  const {
    subtitle
  } = metafields || {};

  const addToCart = (e) => {
    e.preventDefault();
    if(!loading) {
      addVariantToCart(productVariant.storefrontId, quantity);
      isClicked.current = true;
    }
  }

  const handleOptionChange = (index, value) => {

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]
    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  const onMouseEnter = () => {
    setActive(true)
  }

  const onMouseLeave = () => {
    setActive(false)
  }

  if(didJustAddToCart) {
    isClicked.current = false;
  }

  const hoverImage = featuredImage ? product.featuredImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src : null;
  const hoverImageBlur = featuredImage ? product.featuredImage.localFile.childImageSharp.gatsbyImageData.placeholder.fallback.src : null;
  return (  
    <Wrapper>
      <TopWrapper>
        <Title opacity={Active ? 1 : 0}>{title}</Title>
      </TopWrapper>
      <BottomWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <BottomInner>
          <Main>
            <InfoWrapper>
              <InfoPrimary>Includes</InfoPrimary>
              <InfoSecondary>{ subtitle ? subtitle.value : null }</InfoSecondary>
            </InfoWrapper>
            <Statement>{description}</Statement>
            <HoverWrapper opacity={Active ? 1 : 0}>
              <SelectWrapper>
                <SelectTitle>SELECT QUANTITY</SelectTitle>
                { hasVariants && options ? options.map(({ id, name, values }, index) => {

                  return <VariantSelect name={`${handle}-${name}`} callback={(value) => handleOptionChange(index, value)}>
                    {values.map((value, index) => (
                      <VariantSelectItem value={value} key={`${name}-${value}`} theme='light' defaultChecked={index===0 ? true : false}>{value}</VariantSelectItem>
                    ))}
                  </VariantSelect>
                }) : null }
              </SelectWrapper>
              <LazyBackgroundWrapper>
                <LazyBackgroundBlur src={`${hoverImage}`} placeholder={`${hoverImageBlur}`} />
              </LazyBackgroundWrapper>
            </HoverWrapper>
          </Main>
          <QuickAdd>
            { width >= 1080 ? 
            <>
              <AddMini onClick={addToCart} isLoading={loading && isClicked.current} theme="dark">Add to Cart</AddMini>
              <Price>{price}</Price>
            </> : <>
              <Price>{price}</Price>
              <AddMobile onClick={addToCart}>{Active ? 'ADD' : 'SELECT'}</AddMobile>
            </>}
          </QuickAdd>
        </BottomInner>
      </BottomWrapper>
    </Wrapper>

  )
  
}

export default BundleProduct;