import React, { useState } from 'react';
import styled from 'styled-components';
import HtmlParser from '../HtmlParser/HtmlParser';
import ForYouText from './ForYouText';
import TextureCircle from './TextureCircle';
import ReferenceToggle from '../ReferenceToggle/ReferenceToggle';
import Icon from './assets/x.svg';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';

const Wrapper = styled.div` 
  position: relative;
  width: 100%;
`

const Label = styled.div`
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #383B23;
  padding: 20px 0;
  border-bottom: 1px solid #383B23;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`

const MainTitle = styled.h2` 
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500;
  color: #383B23;
  margin: 0 0 20px;
`


const Grouping = styled.div` 
  position: relative;
`

const IngredientsWrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-height: ${props => props.active ? '600px' : '0'};
`

const IngredientsArray = styled.div` 
  display: flex;
  width: 100%;
  position: relative;
  padding: 35px 0;
  justify-content: center;
  border-bottom: 1px solid #383B23;
`

const IngredientsCircle = styled(TextureCircle)`
  position: relative;
  width: 18vw;
  padding: 5px;
  margin: 0 10px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -36px;
    width: 1px;
    height: 36px;
    background: #383B23;
    left: 50%;
    opacity: ${props => props.active ? '1' : '0'};
    transition: opacity .2s ease;
  }
`

const Content = styled.div` 
  position: relative;
  padding: 30px 0;

`

const ToggleIcon = styled(Icon)` 
  transform: rotate(${props => props.active ? '45deg' : '0deg'});
  transition: transform .2s ease;
  z-index: 1;

`

const ForYouMobile = ({content}) => {
  const [ Active, setActive ] = useState(null);
  const [ ActiveIngredient, setActiveIngredient ] = useState(0);

  const activeContent = Active !== null ? content[Active] : null;
  const ingredients = activeContent !== null ? activeContent.ingredients : null;
  const activeIngredient = ingredients !== null ? ingredients[ActiveIngredient] : null;

  const toggle = (index) => {

    if(index !== Active){
      setActive(index);
    } else {
      setActive(null);
    }
  }

  return (
    <Wrapper>
      <MainTitle>What can I do for you?</MainTitle>
      { content ? content.map((section,index) => {

        const ingredients = section.ingredients;

        return (
          <Grouping>
            <Label onClick={()=>{toggle(index); setActiveIngredient(0); }}><HtmlParser>{section.label}</HtmlParser> <ToggleIcon active={Active === index ? true : false}/></Label>
            <IngredientsWrapper active={Active === index ? true : false}>
              <IngredientsArray>
              { ingredients ? ingredients.map((ingredient,index) => {
                return <IngredientsCircle 
                    onClick={()=>setActiveIngredient(index)} 
                    key={ingredient.body.title} 
                    src={ingredient.image} 
                    placeholder={ingredient.placeholder} 
                    active={index === ActiveIngredient ? true : false}
                  />
              }) : null }
              </IngredientsArray>
              <Content>
                { activeIngredient ? <ForYouText title={activeIngredient.body.title} subtitle={activeIngredient.body.subtitle} reference={<ReferenceToggle label='See References'><HtmlParser strip={['p']}>{activeIngredient.body.reference}</HtmlParser></ReferenceToggle>}>
                  <HtmlParser>{activeIngredient.body.content}</HtmlParser>
                </ForYouText> : null }
              </Content>
            </IngredientsWrapper>
          </Grouping>
        )
      }) : null }
      
    </Wrapper>
  )
}

export default ForYouMobile;