import React from 'react';
import styled from 'styled-components';
import HtmlParser from '../HtmlParser/HtmlParser';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const InfoColumns = (props) => {
  const { children } = props;

  const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    ${media.large`
      grid-template-columns: repeat(3, 1fr);
    `}
  `

  return (
    <Wrapper>
      { children }
    </Wrapper>
  )

}

export default InfoColumns;


const InfoColumnWrapper = styled.div`
  padding: 30px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid #383B23;
  }

  &:first-child {
    padding-top: 0;
  } 

  &:last-child {
    padding-bottom: 0;
  } 

  ${media.large`
    padding: 0 12%;

    &:not(:last-of-type) {
      border-right: 1px solid #383B23;
      border-bottom: 0;
    }
  `}

  
`

const Title = styled.h2`
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500;
  color: #383B23;
  margin: 0 0 10px;

  ${media.large`
    ${type('body')}
    font-weight: 500;
  `}
`

const Description = styled.p`
  margin: 0 0 40px;
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;

  ${media.large`
    ${type('body')}
    margin: 0 0 50px;
  `}

  &:last-child {
    margin: 0;
  }
`

const InfoColumn = (props) => {
  const { title, description, children } = props;
  return (
    <InfoColumnWrapper>
      <Title>{title}</Title>
      <Description><HtmlParser>{description}</HtmlParser></Description>
      { children }
    </InfoColumnWrapper>
  )
}

export { InfoColumn };


const SubGroupWrapper = styled.div`
  margin: 20px 0 0;

  ${media.large`
    margin: 30px 0 0;
  `}
`

const SubTitle = styled.h3`
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;
  margin: 0 0 7px;

  ${media.large`
    ${type('body')}
  `}
`

const SubContent = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  opacity: 0.7;
  color: #383B23;
  margin: 0;
  ${media.large`
    ${type('body')}
  `}
`



const SubGroup = (props) => {
  const { title, content } = props;
  return (
    <SubGroupWrapper>
      <SubTitle>{title}</SubTitle>
      <SubContent><HtmlParser>{content}</HtmlParser></SubContent>
    </SubGroupWrapper>
  )
}

export { SubGroup }
