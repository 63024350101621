import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import Icon from './assets/arrow.svg';
import ImageBlock from '../ImageBlock/ImageBlock';
import closeIcon from './assets/close.svg';
import texture from './assets/texture.jpg';
import { media } from '../../utils/mediaQuery';


const Root = styled.div`
  display: grid;
`

const Wrapper = styled.div`
 border: 1px solid #383B23;
 border-radius: 10px;
 position: relative;
 padding-bottom: 123%;
 grid-area: 1 / 1;
`

const InitSide = styled(Wrapper)` 
  transform: ${props => props.active ? 'perspective(2000px) rotateY(90deg)' : 'perspective(2000px) rotateY(0deg)'};
  transition: transform .2s linear .2s;
  ${({active}) => active && `
    transition: transform .2s linear 0s;
  `}
`

const HoverSide = styled(Wrapper)`
  background: url(${texture}) no-repeat center;
  background-size: cover;
  transform: ${props => props.active ? 'perspective(2000px) rotateY(0deg)' : 'perspective(2000px) rotateY(-90deg)'};
  transition: transform .2s linear 0s;
  ${({active}) => active && `
    transition: transform .2s linear .2s;
  `}
  
`

const Inner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  ${media.large`
    padding: 30px;
  `}
`

const Main = styled.div` 
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
`

const Secondary = styled.div` 
  position: relative;
  height: 100%;
  width: 100%;
`

const SecondaryInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const LabelDefault = styled.h2` 
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #383B23;
  margin: 0;

  ${media.large`
    ${type('large')}
    font-weight: 300;
  `}
`

const ThumbWrapper = styled.div` 
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ArrowWrapper = styled(ThumbWrapper)`
  justify-content: flex-end;
  display: ${props => props.isLast ? 'none' : 'flex'};

  ${media.large`
    ${type('body')}
    font-weight: normal;
    margin: 0;
  `}
`

const Thumb = styled.div` 
  position: relative;
  width: 40%;
  overflow: hidden;
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

const Arrow = styled(Icon)`
  stroke: ${props => props.color || '#383B23'};
`

const LabelSecondary = styled.h2` 
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #F3EFD7;
    margin: 0 10px 0 0;
  }

  ${fontstack.default}
  ${type('caption')}
  font-weight: 500;
  color: #F3EFD7;
  margin: 0 0 20px;

  ${media.large`
    ${type('body')}
    font-weight: normal;
    margin: 0;
  `}
`

const Title = styled.h2` 
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #F3EFD7;
  margin: 20 0 0;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    margin: 0;
  `}
`

const Description = styled.p`
  margin: 0;
  ${fontstack.default}
  ${type('caption')}
  color: #F3EFD7;
  padding: 15px 0 0;
  border-top: 1px solid #F3EFD7;

  ${media.large`
    ${type('body')}
  `}
`

const Close = styled(closeIcon)` 
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
  display: none;
  ${media.large`
    display: block;
  `}
`

const ProcessCard = (props) => {
  const { id, card, active, onSelect, isLast, onClose} = props;

  return (  
    <Root onMouseEnter={()=>onSelect ? onSelect(id) : null} onMouseLeave={()=>onClose ? onClose() : null}>
      <InitSide active={active}>
        <Inner>
          <Main>
            <LabelDefault>{card.label}</LabelDefault>
            <ThumbWrapper>
              <Thumb>
                <ImageBlock src={card.thumbnail.url} placeholder={{
                  src: `${card.thumbnail.url}&w=100&blur=10&q=50`,
                  width: card.thumbnail.dimensions.width,
                  height: card.thumbnail.dimensions.height
                }}/>
              </Thumb>
            </ThumbWrapper>
            <ArrowWrapper isLast={isLast}>
              <Arrow />
            </ArrowWrapper>
          </Main>
        </Inner>
      </InitSide>
      <HoverSide active={active}>
        <Inner>
          <Secondary>
            <SecondaryInner>
              <div>
                <LabelSecondary>{card.label}</LabelSecondary>
                <Title>{card.title}</Title>
              </div>
              <Description>{card.copy}</Description>
            </SecondaryInner>
            <ArrowWrapper isLast={isLast}>
              <Arrow color='#F3EFD7'/>
            </ArrowWrapper>
          </Secondary>
        </Inner>
      </HoverSide>
    </Root>
  )
}

export default ProcessCard;