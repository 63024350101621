import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

 const LabelWrapper = styled.label`
 display: flex;
 align-items: center;
 justify-content: center;
 cursor: pointer;
 width: 100%;
 &:not(:last-of-type) {
   border-right: 1px solid #383B23;
 }
`

const Label = styled.div`
 ${fontstack.default}
 ${type('m-body')}
 color:#383B23;
 padding: 20px 0;
 width: 100%;
 text-align: center;

 ${media.large`
   ${type('body')}
 `}
`

const Input = styled.input`
 display: none;

 &:checked ~ ${Label} {
  background: #383B23;
  color: #F2EADE;
 }
`

const SquaredRadio = (props) => {
 const { children, className, ...rest } = props;

 return (
   <LabelWrapper className={className}>
     <Input type="radio" {...rest}/>
     <Label>{children}</Label> 
   </LabelWrapper>
 )

}

export { SquaredRadio };


const Wrapper = styled.div` 
  display: flex;
  width: 100%;
  border: 1px solid #383B23;
`

const SquaredRadioWrapper = (props) => {
  const { children, ...rest } = props;
 
  return (
    <Wrapper {...rest}>
      {children}
    </Wrapper>
  )
 
 }
 
 export { SquaredRadioWrapper };