import React from 'react';
import styled from 'styled-components';
import ImageBlock from '../ImageBlock/ImageBlock';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div` 
  position: relative;
  margin: -40px 0;
  z-index: 0;
`

const DesktopBlock = styled(ImageBlock)` 
  display: none;
  ${media.large`
    display: block;
  `}
`

const MobileBlock = styled(ImageBlock)` 
  display: block;
  ${media.large`
    display: none;
  `}
`

const SunkenImage = (props) => {
  const { data, src, placeholder } = props;


  const desktopSrc = data.url;

  const desktopPlaceholder = {
    src: `${desktopSrc}&w=100&blur=10&q=50`,
    width: data.dimensions ? data.dimensions.width : null,
    height: data.dimensions ? data.dimensions.height : null
  }

  const mobileSrc = data.thumbnails.mobile.url;
  const mobilePlaceholder = {
    src: `${mobileSrc}&w=100&blur=10&q=50`,
    width: data.thumbnails.mobile.dimensions ? data.thumbnails.mobile.dimensions.width : null,
    height: data.thumbnails.mobile.dimensions ? data.thumbnails.mobile.dimensions.height: null
  }
  
  return (
    <Wrapper>
      <MobileBlock src={mobileSrc} placeholder={mobilePlaceholder} alt={data.thumbnails.mobile.alt}/>
      <DesktopBlock src={desktopSrc} placeholder={desktopPlaceholder} alt={data.alt}/>
    </Wrapper>
  )
}

export default SunkenImage;