import React from 'react';
import styled from 'styled-components';
import { Accordion, AccordionItem } from '../Accordion/Accordion';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';


const Image = styled.img` 
  position: relative;
  width: 100%;
`

const ProductDetailAccordion = ({content, ...props}) => {


  return (
    <>
    { content && content.length > 0 ?
      <Accordion initActive={0} {...props}>
        { content.map((data) => {
          if(!data.label && !data.content.html) 
            return
          
          return <AccordionItem label={data.label} image={data.image.url ? <Image src={data.image.url} alt={data.image.alt}/> : null }>{data.content.html}</AccordionItem>
        })}
    </Accordion> : null }
    </>
  )
}

export default ProductDetailAccordion;