import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Link as ScrollLink } from 'react-scroll'
import { StoreContext } from "../../context/store-context"
import isEqual from "lodash.isequal"
import Tag from '../Tag/Tag';
import ImageBlock from '../ImageBlock/ImageBlock';
import CreateAdd from '../CreateAdd/CreateAdd';
import HtmlParser from '../HtmlParser/HtmlParser';
import { Quantity } from '../Forms/Forms';
import { VariantSelect, VariantSelectItem } from '../VariantSelect/VariantSelect';
import { SquaredRadio, SquaredRadioWrapper } from '../SquaredRadio/SquaredRadio';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { linkhover } from '../../utils/lineHover';
import { media } from '../../utils/mediaQuery';
import { getMetafields } from '../../utils/getMetafields';
import { formatPrice } from "../../utils/format-price"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  ${fontstack.default}
  color: #383B23;
`

const ProductName = styled.h1`
  position: relative;

`

const AddToCart = styled(CreateAdd)` 
  max-width: unset;
  
`

const ProductTag = styled(Tag)`
  margin: 0 0 10px;
  ${type('caption')}
  padding: 8px 10px;
  ${media.large`
    ${type('body')}
    margin: 0 0 30px;
    padding: 10px 15px;
  `}
`

const Title = styled.span`
  ${type('m-heading')}
  font-weight: 300;
  margin: 0 0 30px;
  display: block;

  ${media.large`
    ${type('large')}
    font-weight: 300;
  `}
`

const SubTitle = styled.span`
  ${type('m-body')}
  font-weight: 500;
  display: block;

  ${media.large`
    ${type('body')}
    font-weight: 500;
  `}
`

const DescriptionWrapper = styled.div`
  margin: 10px 0 20px;
  flex: 1;
`

const Description = styled.p`
  ${type('m-body')} 
  margin: 0;

  ${media.large`
    ${type('body')} 
  `}
`

const BottomWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 40px 0 0;

  ${media.large`
    margin: 0;
    width: 80%;
    min-width: 300px;
  `}
`

const SizeSelect = styled(VariantSelect)` 
  margin: 0 0 30px;
`

const VariantWrapper = styled.div`
  
`

const LinkMod = styled(Link)`
display: inline-block;
position: relative;
cursor: pointer;
${linkhover('#383B23')}
`

const ScrollLinkMod = styled(ScrollLink)`
  display: inline-block;
  position: relative;
  cursor: pointer;
  ${linkhover('#383B23')}
`

const VariantLabel = styled.div`
  ${type('m-body')} 
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 20px;

  ${media.large`
    margin: 0 0 15px;
    ${type('body')} 
    font-weight: 500;
  `}
`

const OtherCopy = styled.div`
  ${type('m-body')} 
  margin: 50px 0 0;
  width: 100%;
  max-width: 300px;

  ${media.large`
    ${type('body')} 
  `}
`

const ImageRounded = styled.div`
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 0 60px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

const ProductDetailMain = ({product}) => {
  
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    descriptionHtml,
    media,
    handle,
    productType,
  } = product
  const { client, width } = React.useContext(StoreContext)
  const { addVariantToCart, loading } = React.useContext(StoreContext)
  const hasVariants = variants.length > 1

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1);

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const metafields = getMetafields(product);

  const tag = metafields?.tag;
  const subtitle = metafields?.subtitle;

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price * quantity
  )

  const firstImage = media ? media[0] : null;

  const addToCart = (e) => {
    e.preventDefault();
    if(!loading) {
      addVariantToCart(productVariant.storefrontId, quantity);
    }
  }

  const handleOptionChange = (index, value) => {

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]
    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  const hasStyle = options.filter(option => option.name === 'Style');

  return (
    <Wrapper>
      { tag ? <ProductTag theme={width >= 1080 ? 'outline' : null}>{tag.value}</ProductTag> : null }
      { width < 1080 && firstImage ? <ImageRounded>
          <ImageBlock alt={firstImage.alt} src={firstImage.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src} placeholder={{ src: firstImage.image.localFile.childImageSharp.gatsbyImageData.placeholder.fallback, width: firstImage.image.localFile.childImageSharp.gatsbyImageData.width, height: firstImage.image.localFile.childImageSharp.gatsbyImageData.height }}/>
        </ImageRounded>  : null }
      <ProductName>
        <Title>{title}</Title>
        <SubTitle>{subtitle?.value}</SubTitle>
      </ProductName>
      <DescriptionWrapper>
        <HtmlParser>{descriptionHtml}</HtmlParser>
      </DescriptionWrapper>
      <BottomWrapper>
        <VariantWrapper>
          { hasVariants && options ? <> <VariantLabel>select quantity</VariantLabel>{ 
            options.map(({ id, name, values }, index) => {
            if(name==='Size') {
              return (
                <SizeSelect name={name} callback={(value) => handleOptionChange(index, value)}>
                  {values.map((value, index) => (
                    <VariantSelectItem value={value} key={`${name}-${value}`} defaultChecked={index===0 ? true : false}>
                      {value}
                    </VariantSelectItem>
                  ))}
                </SizeSelect>
              )
            }

            if(name==='Style') {
              return ( <SquaredRadioWrapper onChange={(event) => handleOptionChange(index, event.target.value)}>
                {values ? values.map((value, index) => {

                  return <SquaredRadio name={name} value={value} key={`${name}-${value}`} defaultChecked={index===0 ? true : false}>{value}</SquaredRadio>
      
                }) : null }
              </SquaredRadioWrapper>)
            }

            
          }) }
          <Quantity step='1' min='1' max='20' maxlength='2' value={quantity} theme='dark' callback={(value)=>setQuantity(value)} border={hasStyle && hasStyle.length > 0 ? true : false} fullBorder={hasStyle && hasStyle.length > 0 ? false : true} activeButtons/> 
          </> : <Quantity step='1' min='1' max='20' maxlength='2' value={quantity} theme='dark' callback={(value)=>setQuantity(value)} fullBorder activeButtons/>  }
          
        </VariantWrapper>
        <AddToCart onClick={addToCart} isLoading={loading}>{price}</AddToCart>
      </BottomWrapper>
      { productType === 'icebar' ? 
      <OtherCopy>Want more or less of this and that?<b><LinkMod to="/create-your-own">Personalise your own set</LinkMod></b>, or<br/><b><ScrollLinkMod to="bundles" smooth={true} duration={800}>shop our suggested bundles here</ScrollLinkMod></b></OtherCopy> 
      : productType === 'granola' ? <OtherCopy>Want more or less of this and that?<br/><b><ScrollLinkMod to="bundles" smooth={true} duration={800}>Shop our suggested sets here.</ScrollLinkMod></b></OtherCopy> : null }
    </Wrapper>
  )
}

export default ProductDetailMain;