import React, { useState } from 'react';
import styled from 'styled-components';
import HtmlParser from '../HtmlParser/HtmlParser';
import ForYouV3 from './ForYouV3';
import ForYouMobile from './ForYouMobile';
import ForYouRow from './ForYouRow';
import ForYouText from './ForYouText';
import ReferenceToggle from '../ReferenceToggle/ReferenceToggle';
import LabelPanel from './LabelPanel';
import TextureCircle, { TextureCircleContainer } from './TextureCircle';
import { StoreContext } from '../../context/store-context';
import KaleImage from './assets/Kale.jpg';
import KaleBlurImage from './assets/Kale_blur.jpg';
import SpirulinaImage from './assets/Spirulina.jpg';
import SpirulinaBlurImage from './assets/Spirulina_blur.jpg';
import WheatgrassImage from './assets/Wheatgrass.jpg';
import WheatgrassBlurImage from './assets/Wheatgrass_blur.jpg';

const Wrapper = styled.div` 
  position: relative;
  overflow: hidden;
`

const ForYou = ({content}) => {

  const shapedContent = content && content.length > 0 ? 
    content.reduce((acc,curr) => {

      const subFunctionObject = acc.filter((sub)=> sub.label === curr.sub_function);
      const ingredient = {
        image: curr.ingredient_image.url,
        placeholder: {
          src: `${curr.ingredient_image.url}&w=100&blur=10&q=50`,
          width: curr.ingredient_image.dimensions ? curr.ingredient_image.dimensions.width : null,
          height: curr.ingredient_image.dimensions ? curr.ingredient_image.dimensions.height : null
        },
        alt: curr.ingredient_image.alt,
        body: {
          title: curr.ingredient,
          subtitle: curr.tag_line,
          reference: curr.references.html,
          content: curr.description.html
        }
      }

      if(subFunctionObject && subFunctionObject.length > 0) {
        subFunctionObject[0].ingredients.push(ingredient)
      } else {
        acc.push({
          icon: curr.sub_function_icon.url,
          label: curr.sub_function,
          ingredients: [ingredient]
        })
      }
      
      return acc;
    },[])
  : null;

  const testcontent = [
    { 
      label: 'Remove Toxins + Heavy Metals',
      ingredients: [
        {
          image: SpirulinaImage,
          placeholder: {
            src: SpirulinaBlurImage,
            width: 20,
            height: 20
          },
          body: {
            title: 'Spirulina',
            subtitle: 'Miracle of the Sea',
            reference: '[1] Nilay S., Sevda I., Cenk A. A Prominent Superfood: Spirulina Platensis. Superfood and Functional Food - The Development of Superfoods and Their Roles as Medicine. 2017 Feb. doi: 10.5772/66118',
            content: 'One of the most complete protein sources in the plant kingdom. Abundant in vitamins (A, B1, B7, B12, E), minerals (iron, calcium), and phytonutrients (carotenoids, chlorophyll, phycocyanin) that work together synergistically to strengthen your immune system and help eliminate toxins.[1]'
          }
        },
        {
          image: WheatgrassImage,
          placeholder: {
            src: WheatgrassBlurImage,
            width: 20,
            height: 20
          },
          body: {
            title: 'Wheatgrass',
            subtitle: 'Chlorophylled with Benefits',
            reference: '[1] Gore RD, Palaskar SJ, Bartake AR. Wheatgrass: Green Blood can Help to Fight Cancer. J Clin Diagn Res. 2017;11(6):ZC40-ZC42. doi:10.7860/JCDR/2017/26316.10057',
            content: 'A potent fighter against toxicity induced by heavy metals. High contents of chlorophyll can bind with toxins and reduce our absorption of these harmful substances. It also has a similar structure to haemoglobin, a protein within our red blood cells, thereby enhancing oxygen supply around our bodies.[1]'
          }
        }
      ]
      
    },
    { 
      label: 'Promotes Smooth Digestion',
      ingredients: [
        {
          image: SpirulinaImage,
          placeholder: {
            src: SpirulinaBlurImage,
            width: 20,
            height: 20
          },
          body: {
            title: 'Spirulina',
            subtitle: 'Miracle of the Sea',
            reference: '[1] Nilay S., Sevda I., Cenk A. A Prominent Superfood: Spirulina Platensis. Superfood and Functional Food - The Development of Superfoods and Their Roles as Medicine. 2017 Feb. doi: 10.5772/66118',
            content: 'One of the most complete protein sources in the plant kingdom. Abundant in vitamins (A, B1, B7, B12, E), minerals (iron, calcium), and phytonutrients (carotenoids, chlorophyll, phycocyanin) that work together synergistically to strengthen your immune system and help eliminate toxins.[1]'
          }
        }
      ]
    },
    { 
      label: 'Cleanses + Protects the Liver',
      ingredients: [
        {
          image: KaleImage,
          placeholder: {
            src: KaleBlurImage,
            width: 20,
            height: 20
          },
          body: {
            title: 'Kale',
            subtitle: 'Super Sulforaphane',
            reference: '[1] Kim JK, Park SU. Current Potential Health Benefits of Sulforaphane. EXCLI J. 2016;15:571-577. Published 2016 Oct 13. doi:10.17179/excli2016-485<br/><br/>[2] Tortorella SM, Royce SG, Licciardi PV, Karagiannis TC. Dietary Sulforaphane in Cancer Chemoprevention: The Role of Epigenetic Regulation and HDAC Inhibition. Antioxid Redox Signal. 2015;22(16):1382-1424. doi:10.1089/ars.2014.6097',
            content: 'This cruciferous vegetable oozes this phytonutrient sulforaphane, which is essential in activating production of liver detox enzymes.[1] It also assists in neutralising harmful carcinogens in the body and offers anti-ageing benefits.[2]'
          }
        }
      ]
    }
  ]

  const { width } = React.useContext(StoreContext);

  return (
    <>
    { width >= 1080 ? <ForYouV3 content={shapedContent || testcontent}/> : <ForYouMobile content={shapedContent || testcontent} /> }
    </>
  )
}

export { ForYou }