import React, { useState } from 'react';
import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll'
import { NewsTicker as ManualTicker } from '@manualengineering/react-news-ticker';
import CSSNewsTicker from './CSSNewsTicker';
import UpIcon from './assets/arrow.svg';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';


const MainWrapper = styled.div`
  position:  relative;
`
const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 26px 0 26px 20px;
  background: #F2EADE34;
  backdrop-filter: blur(34px);
  border: 1px solid #383B23;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  pointer-events: none;
  ${media.large`
    padding: 30px 0 30px 30px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    pointer-events: all;
  `}

  @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) and (min-width:1080px){
    background: #F2EADE60;
    backdrop-filter: none;
  }
`

const TickerWrapper = styled.div` 
  position: relative;
  flex: 1;
  &:before {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, rgba(242,234,222,1) 0%, rgba(242,234,222,0) 20%);
  }
`

const Title = styled.h2`
  ${fontstack.default};
  ${type('m-large')}
  font-weight: 300;
  line-height: 1;
  margin: 0 40px 0 0;
  color:  ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  text-transform: uppercase;
  transition: color .2s ease;
  z-index: 3;
  word-break: keep-all;
  ${media.large`
    ${type('large')}
    font-weight: 300;
    margin: 0 120px 0 0;
  `}
`

const Attribute = styled.div` 
  position: relative;
  white-space: pre;
  ${fontstack.default};
  ${type('m-large')}
  line-height: 1;
  font-weight: 300;
  margin: 0;
  color: #383B23;

  ${media.large`
    ${type('large')}
    font-weight: 300;
  `}
  
`

const Divider = styled.div`
  ${fontstack.default};
  font-size: 18px;
  line-height: 1;
  font-weight: 300;
  margin: 0;
  color: #383B23;
  ${media.large`
    font-size: 25px;
    font-weight: 300;
  `}
  
`

const HoverCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  box-sizing: border-box;
  padding: 0 20px;
  background: #383B23;
  opacity: ${props => props.isHover ? '1' : '0'};
  transition: opacity .2s ease;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${media.large`
    padding: 0 30px;
  `}

`
const Arrow = styled(UpIcon)` 
  position: relative;
  height: 50%;
`

const CustomCSSNewsTicker = styled(CSSNewsTicker)`
  position: absolute;
`

const ProductTicker = ({title, attributes, ...rest}) => {
  const [ isHover, setIsHover ] = useState(false);
  const items = attributes.map((attr) => {

    return [
    <Attribute>{attr}</Attribute>,
    <Divider>+</Divider>
    ];
  })
  return ( 
    <MainWrapper {...rest}>
      <ScrollLink to="head" smooth={true} duration={800} offset={-80}>
          <Wrapper onMouseEnter={()=>setIsHover(true)}  onMouseLeave={()=>setIsHover(false)}> 
          <Title theme={isHover ? 'light' : 'dark'}>{title}</Title>
          <HoverCover isHover={isHover}>
            <Arrow/>
          </HoverCover>
          <TickerWrapper>
            <CustomCSSNewsTicker nopad speed={'40s'}>
              {items}
            </CustomCSSNewsTicker>
          </TickerWrapper>
        </Wrapper>
      </ScrollLink>
    </MainWrapper>
  )
}

export default ProductTicker;