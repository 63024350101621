import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const VariantWrapper = styled.div`
display: flex;
`

const VariantSelect = (props) => {
  const { children, name, callback, ...rest } = props;

  const onChange = (event) => {
    const value = event.target.value;
    if(callback) {
      callback(value);
    }
  }

  const radios = React.Children.map(children, child => {
    if(React.isValidElement(child)) {
      return React.cloneElement(child,{ name:name, onChange: onChange});
    }
    return child;
  });

  return (
    <VariantWrapper {...rest}>
      {radios}
    </VariantWrapper>
  )
}

export { VariantSelect }

const LabelWrapper = styled.label`
  display: flex;
  align-items: center;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  &:not(:last-of-type) {
    margin: 0 30px 0 0;
  }
`

const Label = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500;
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};

  ${media.large`
    ${type('body')}
    font-weight: 500; 
  `}
`

const Dot = styled.div`
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  margin: 0 10px 0 0;
  border: 1px solid ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  transition: background .2s ease;
`

const Input = styled.input`
  display: none;

  &:checked ~ ${Dot} {
    background: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};;
  }
`

const VariantSelectItem = (props) => {
  const { value, children, theme, ...rest } = props;

  return (
    <LabelWrapper>
      <Input type="radio" value={value} theme={theme} {...rest}/>
      <Dot theme={theme}/>
      <Label theme={theme}>{children}</Label>   
    </LabelWrapper>
  )

}

export { VariantSelectItem }