import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar } from "swiper";
import Bracket from '../Bracket/Bracket';
import Section from '../Section/Section';
import ImageBlock from '../ImageBlock/ImageBlock';
import ProductDetailMain from './ProductDetailMain';
import ProductDetailAccordion from './ProductDetailAccordion';
import { fontstack } from '../../utils/fontstack';
import { media } from '../../utils/mediaQuery';
import { type } from '../../utils/type';
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "../../styles/swiper-scrollbar.css";
gsap.registerPlugin(ScrollTrigger);

const Viewport = styled.div`
  position: relative;
`

const HeadViewport = styled(Viewport)` 
  overflow: hidden;
  display: flex;
`

const ImagesViewport = styled.div` 
  width: 100%;
  background: #FAF7F2;
`

const AccordionSection = styled(Section)` 
  background:#FAF7F2;
`

const Grid = styled(Section)`
  display: grid;
  grid-template-columns: repeat(12,1fr);
  grid-column-gap: 30px;
  margin-top: 40px;
  margin-bottom: 60px;
  width: 100%;
`

const OverflowHidden = styled.div` 
  position: relative;
  overflow: hidden;
  background: #FAF7F2;
`

const Images = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  border-radius: 10px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

const ImageWrapper = styled.div`
  grid-column: 1 / span 12;
  display: flex;
  flex-direction: column;
  justify-content: center ;
` 

const ImageRounded = styled.div`
  border-radius: 10px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  width: 100%;
`

const ImageRoundedWrapper = styled.div` 
  position: relative;
  width: 100%;
  grid-area: 1 / 1;
`

const ImageCount = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  background: #FAF7F2;
  margin: 20px 0;

`
const AbsoluteImageRounded = styled(ImageRounded)`
  position: absolute;
  top: 0;
  z-index: 1;
  opacity: 0;
`

const MainWrapper = styled.div`
  grid-column: 1 / span 12;  
  box-sizing: border-box;
`

const ProductDetailHeadMobile = ({product, content}) => {

  useEffect(()=>{


    return () => {

    }

  },[]);

  const {
    media
  } = product;

  const { 
    accordion 
  } = content || {};

  const secondImageSet = media.filter((image,index) => index >=1 );

  const ViewportRef = useRef();
  const ImagesRef = useRef([]);
  const Controller = useRef();
  return (
  <>
    <Bracket bottom bottomShift zIndex={2} background='#F2EADE'>
      <HeadViewport>
        <Grid small={true}>
          <MainWrapper>
            <ProductDetailMain product={product}/>
          </MainWrapper>
        </Grid>
      </HeadViewport>
    </Bracket>
    { secondImageSet && secondImageSet.length > 0 && 
    <OverflowHidden>
      <ImagesViewport>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={10}
          scrollbar={true}
          modules={[Scrollbar]}
          className="mySwiper"
          style={{padding:'0 20px 30px', margin:'120px 0 80px'}}
        >
          { secondImageSet ? secondImageSet.map((object,index)=> {
              const image = object.image;
              return (
                <SwiperSlide style={{width: secondImageSet.length === 1 ? '100%' : '80%'}}>
                  <ImageRounded>
                    <ImageBlock 
                    alt={object.alt} 
                    src={image.localFile.childImageSharp.gatsbyImageData.images.fallback.src} 
                    placeholder={{ 
                      src: image.localFile.childImageSharp.gatsbyImageData.placeholder.fallback, 
                      width: image.localFile.childImageSharp.gatsbyImageData.width, 
                      height: image.localFile.childImageSharp.gatsbyImageData.height
                       }}
                    />
                  </ImageRounded>
                </SwiperSlide>
              )
          }) : null }

        </Swiper>
      </ImagesViewport>
    </OverflowHidden> }
    <AccordionSection none>
      { accordion ? <ProductDetailAccordion content={accordion}/> : null }
    </AccordionSection>
  </>
  )
}

export default ProductDetailHeadMobile;