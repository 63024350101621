import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { Element } from 'react-scroll';
import { Layout } from '../../../components/Layout/layout';
import Section from '../../../components/Section/Section';
import Bracket from '../../../components/Bracket/Bracket';
import ProductTicker from '../../../components/NewsTicker/ProductTicker';
import ProductDetailHead from '../../../components/ProductDetailPage/ProductDetailHead';
import ProductDetailHeadMobile from '../../../components/ProductDetailPage/ProductDetailHeadMobile';
import { ForYou } from '../../../components/ForYou/ForYou';
import Bundles from '../../../components/Bundles/Bundles';
import { Seo } from "../../../components/seo"
import SmoothieConcept from '../../../components/SmoothieConcept/SmoothieConcept';
import Recycle from '../../../components/Recycle/Recycle';
import NatureToYou from '../../../components/NatureToYou/NatureToYou';
import { getMetafields } from '../../../utils/getMetafields';
import SunkenImage from '../../../components/SunkenImage/SunkenImage';
import { StoreContext } from '../../../context/store-context';
const SunkenWrapper = styled.div`
  position: sticky;
  z-index: 0;
  bottom: 0px;
  display: block;
`

const OverflowHidden = styled.div`
  overflow: hidden;
`

const HeadElement = styled(Element)` 
  position: relative;
  z-index: 2;
`

const TempWrapper = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 60px;
    display: block;
    z-index: 1;
    top: -2px;
  }
`

const FixedTicker = styled(ProductTicker)`
  position: sticky;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  overflow: hidden;
`
export default function Product({ data: { product, product_content, bundles }}) {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    seo,
    title,
    productType,
    description,
    handle,
    media: [firstImage ]
  } = product;

  const prismicContent = product_content ? product_content.data : null;
  const {
    accordion,
    ticker_attributes,
    shopify_handle,
    concept,
    what_can_i_do_for_you,
    large_pinned_image,
    storage_and_recycling,
    nature_to_you
  } = prismicContent || {};

  const { width } = React.useContext(StoreContext);
  const metafields = getMetafields(product);
  const sunkenImage = metafields && metafields.lg_pin ? metafields.lg_pin.localImage.childImageSharp.gatsbyImageData : null;

  // Sift through tags of bundle product to see if it contains this pdp product
  const productRelatedBundles = bundles.edges.filter((edge,index) => {
    const product = edge.node;
    const tags = product.tags;
    let isMatched = false;

    tags.forEach((tag) => {
      if(tag === handle) {
        isMatched = true;
      }
    });

    if(isMatched)
      return true;
  });
  console.log(product);
  const formattedProductType = productType ? productType[0].toUpperCase() + productType.slice(1).toLowerCase() : null;
  const pageTitle = formattedProductType ? `${title} ${formattedProductType}` : title;
  return (
    <Layout squared>
      {firstImage ? (
        <Seo
          title={seo.title || pageTitle}
          description={seo.description || description}
          image={getSrc(firstImage.gatsbyImageData)}
        />
      ) : undefined}
      <HeadElement name="head">
        { width >= 1080 ? <ProductDetailHead product={product} content={prismicContent}/> : <ProductDetailHeadMobile product={product} content={prismicContent}/> }
      </HeadElement>
      { large_pinned_image || what_can_i_do_for_you && what_can_i_do_for_you[0]?.sub_function ?
      <TempWrapper>
        <Bracket bottom zIndex={1} background="#FAF7F2" spacer={!what_can_i_do_for_you || !what_can_i_do_for_you[0]?.sub_function ? true : false}>
        { what_can_i_do_for_you && what_can_i_do_for_you[0]?.sub_function && <Section>
            <ForYou content={what_can_i_do_for_you} />
          </Section> }
        </Bracket> 
        { large_pinned_image && large_pinned_image.url ? 
        <SunkenWrapper>
          <SunkenImage data={large_pinned_image} alt={`${title} hero photo`}/>
        </SunkenWrapper> : null }
      </TempWrapper> : null}  
      {productRelatedBundles && productRelatedBundles.length > 0 && <Bracket top bottom zIndex={1} background="#FAF7F2">
        <Element name="bundles">
          <Bundles title={title} bundles={productRelatedBundles}/>
        </Element>
      </Bracket>}
      { concept && concept.document && <Bracket bottom topShift zIndex={0} background='#383B23'>
      <SmoothieConcept data={concept.document.data} /> 
      </Bracket> }
      { storage_and_recycling && storage_and_recycling.document ? <Recycle data={storage_and_recycling.document.data} isLast={!nature_to_you || !nature_to_you.document }/> : null }
      { nature_to_you && nature_to_you.document ? 
        <OverflowHidden>
          <NatureToYou data={nature_to_you.document.data} />
        </OverflowHidden> : null }
        { ticker_attributes ? <FixedTicker title={title} attributes={ticker_attributes.split(',')}/> : null }
        
    </Layout>
  )

}


export const query = graphql`
  query($id: String!, $productType: String!, $handle: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      seo {
        description
        title
      }
      handle
      description
      descriptionHtml
      productType
      tags
      metafields {
        key
        value
        localImage {
          childImageSharp {
            gatsbyImageData(width: 1500, placeholder: BLURRED, quality: 80)
          }
        }
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      media {
        id
        alt
        ... on ShopifyMediaImage {
          id
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1200
                  placeholder: BLURRED
                  height: 1410
                  layout: FIXED
                  aspectRatio: 1
                  transformOptions: {cropFocus: CENTER}
                  blurredOptions: {width: 200})
              }
            }
          }
        }
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
    product_content: prismicProductDetailPage(
      data: {shopify_product: {handle: {eq: $handle}}}
    ) {
      data {
        shopify_product {
          id
          handle
        }
        ticker_attributes
        accordion {
          label
          content {
            html
          }
          image {
            alt
            url
          }
        }
        what_can_i_do_for_you {
          tag_line
          sub_function
          sub_function_icon {
            url
          }
          description {
            html
          }
          ingredient
          ingredient_image {
            alt
            dimensions {
              height
              width
            }
            url(imgixParams: {q: 80})
          }
          references {
            html
          }
        }
        large_pinned_image {
          thumbnails {
            mobile {
              url
              alt
              dimensions {
                height
                width
              }
            }
          }
          alt
          dimensions {
            height
            width
          }
          url(imgixParams: {q: 70, width: 3000})
        }
        storage_and_recycling {
          document {
            ... on PrismicStorage {
              id
              data {
                main_image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url(imgixParams: {q: 80, width: 3000})
                }
                body {
                  ... on PrismicStorageDataBodyColumn {
                    id
                    primary {
                      title
                      description {
                        html
                      }
                    }
                    items {
                      content {
                        html
                      }
                      title
                    }
                  }
                }
              }
            }
          }
        }
        nature_to_you {
          document {
            ... on PrismicNatureToYou {
              id
              data {
                title_header
                card {
                  title
                  label
                  copy
                  background {
                    url(imgixParams: {width: 400, q: 80})
                    dimensions {
                      height
                      width
                    }
                  }
                  thumbnail {
                    alt
                    dimensions {
                      height
                      width
                    }
                    url(imgixParams: {q: 80, width: 300})
                  }
                }
              }
            }
          }
        }
        concept {
          document {
            ... on PrismicConcept {
              id
              data {
                main_copy
                title_header
                sub_copy
                method {
                  title {
                    richText
                    html
                  }
                  image {
                    alt
                    url(imgixParams: {q: 80, width: 750})
                  }
                  video {
                    url
                  }
                  content {
                    richText
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
    bundles: allShopifyProduct(filter: {productType: {eq: "bundle"}}) {
      edges {
        node {
          title
          totalInventory
          description
          handle
          tags
          metafields {
            value
            key
          }
          variants {
            availableForSale
            storefrontId
            title
            selectedOptions {
              name
              value
            }
            price
          }
          options {
            name
            values
            id
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FIXED
                  blurredOptions: {width: 200}
                  quality: 100
                  width: 1200
                )
              }
            }
          }
        }
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`
