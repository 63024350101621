import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import Section from '../Section/Section';
import ImageBlock from '../ImageBlock/ImageBlock';
import InfoColumns, { InfoColumn, SubGroup } from '../InfoColumns/InfoColumns';
import { media } from '../../utils/mediaQuery';
import PackageImage from './assets/Packaging_1.jpg';
import PackageBlurImage from './assets/Packaging_1_blur.jpg';


const Wrapper = styled(Section)`
  ${({isLast}) => !isLast && `
    margin-bottom: 0;
  `};
`

const Title = styled.h2`
  margin: 0 0 30px;
  ${fontstack.default}
  color: #383B23;
  ${type('m-large')}
  font-weight: 300;
  text-align: center;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    margin: 0 0 50px;
  `}
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
`

const InfoWrapper = styled.div`
  margin: 60px 0 0;
  ${media.large`
    margin: 100px 0 0;
  `}
`

const Recycle = ({data,isLast}) => {
  const {
    main_image,
    body
  } = data;

  return (
    <Wrapper isLast={isLast}>
      <Title>Storage and Packaging Recycling</Title>
      { main_image ? <ImageWrapper>
        <ImageBlock src={main_image.url} placeholder={{
          src: `${main_image.url}&w=100&blur=10&q=50`,
          width: main_image.dimensions.width,
          height: main_image.dimensions.height
        }} alt={main_image.alt}/>
      </ImageWrapper> : null }
      { body && body.length > 0 ? <InfoWrapper>
        <InfoColumns>
          { body.map((column) => {
            return (
            <InfoColumn 
              title={column.primary.title}
              description={column.primary.description.html}>
                {column.items && column.items.length > 0 ? column.items.map((sub) => {
                  if(!sub.title && !sub.content.html)
                    return

                  return <SubGroup title={sub.title} content={sub.content.html}/>
                  }) : null }
            </InfoColumn>
            )
          }) }
        </InfoColumns>
      </InfoWrapper> : null }
    </Wrapper>
  )
}

export default Recycle;